import "react-app-polyfill/ie11"
import "core-js/features/array/find"
import "core-js/features/array/includes"
import "core-js/features/number/is-nan"

import { registerApplication, start } from "single-spa"

import * as Sentry from "@sentry/react"
import reportWebVitals from "./utils/reportWebVitals"

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
})

registerApplication({
  name: "@braincheck/report-viewer",
  app: () => import("./root.app"),
  activeWhen: "/",
})

start()
reportWebVitals()
